var $carousel = $('.carousel-set .royal-slider'),
  $imageCountCarousel = $carousel.find('img').length;

if ($('.royal-slider').children().length > 1) {
  //Check if there are any images for the slider to work.
  if ($imageCountCarousel > 0) {
    $carousel.each(function() {
      $(this).royalSlider({
        arrowsNav: false,
        loop: true,
        keyboardNavEnabled: false,
        controlsInside: false,
        imageScaleMode: 'fill',
        slidesSpacing: 3,
        arrowsNavAutoHide: false,
        thumbsFitInViewport: false,
        controlNavigation: 'none',
        navigateByClick: true,
        startSlideId: 0,
        transitionType: 'move',
        globalCaption: false,
        autoHeight: true,
        addActiveClass: true,
        autoPlay: {
          // autoplay options go gere
          enabled: false,
          pauseOnHover: true,
          delay: 2000
        }
      });
    });
  } else {
    // If there are no images then remove the slider all together so the page will lay out correctly.
    $('.carousel-set').remove();
  }

}
